// @flow
// $FlowFixMe
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import Qs from 'qs';
import { omit, get } from 'lodash';

import LoadingIndicator from '../common/LoadingIndicator';
import * as livestreamActions from '../../actions/livestreamEvents';
import * as notificationActions from '../../actions/notifications';
import { selectBaseUrl, selectIsInApp } from '../../selectors/client';
import { getEntityUrl } from '../../lib/entities';
import { CONSTANT_MESSAGES, ENTITY_TYPE_LIVESTREAM_EVENT } from '../../constants';
import buttonStyles from './EasyConnectButton.css';
import styles from './AccessZoomButton.css';
import { ACCESS_ZOOM_ERROR } from '../../lib/notifications';
import ReactTooltip from 'react-tooltip';
import { compose } from 'redux';
import type { IntlShape } from 'react-intl';
import AccessZoomNotOpened from '../messages/AccessZoomNotOpened';
import AccessZoomCapacitor from '../messages/AccessZoomCapacitor';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  id: string,
  slug: string,
  className: ?string,
  isWithinAccessPeriod: boolean,
  sessionNo: number,
};

type MapStateToProps = {
  baseUrl: string,
  isInApp: boolean,
  hasZoomNativeApp: boolean,
};

type DispatchProps = {
  addNotification: Function,
  loadZoomJWT: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const messages = defineMessages({
  accessSession: {
    id: 'course.session.access',
    defaultMessage: 'Access Session',
  },
});

const AccessZoomButton = ({
  id,
  slug,
  className,
  isWithinAccessPeriod,
  sessionNo,
  baseUrl,
  isInApp,
  hasZoomNativeApp,
  addNotification,
  loadZoomJWT,
  intl,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState(null);

  const meetingUrl = useMemo(() => {
    if (!meetingInfo) {
      return null;
    }

    const { joinUrl } = meetingInfo;

    if (isInApp && hasZoomNativeApp && joinUrl) {
      return joinUrl;
    }

    const queryString = Qs.stringify({
      ...omit(meetingInfo, 'joinUrl'),
      inApp: 1, // for clean chrome UI
      leaveUrl: `${baseUrl}${getEntityUrl(ENTITY_TYPE_LIVESTREAM_EVENT, { slug })}`,
    });
    return `${baseUrl}/zoom?${queryString}`;
  }, [meetingInfo, baseUrl, hasZoomNativeApp, isInApp, slug]);

  const loadMeeting = useCallback(async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      const { normalized } = await loadZoomJWT(slug, id);
      setMeetingInfo(normalized);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      setIsLoading(false);
      addNotification(ACCESS_ZOOM_ERROR);
    }
  }, [id, slug, loadZoomJWT, addNotification]);

  useEffect(() => {
    if (!__CAPACITOR__ && isWithinAccessPeriod) {
      loadMeeting();
    }
  }, [loadMeeting, isWithinAccessPeriod]);

  const onClickJoinMeeting = async e => {
    if (__CAPACITOR__ || !isWithinAccessPeriod) {
      e.preventDefault();
      return;
    }
  };

  const classNames = classnames(className, {
    [buttonStyles.isLoading]: isLoading,
    [buttonStyles.isDisabled]: isLoading || __CAPACITOR__,
  });

  const componentId = `access-zoom-btn-${id}`;

  const accessSessionText = intl.formatMessage(messages.accessSession);
  const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: accessSessionText,
  });

  return (
    <div className={styles.container}>
      <a
        className={classNames}
        href={meetingUrl}
        onClick={onClickJoinMeeting}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLink}
        data-test={`livestream-event.course-session.access-session-btn-${sessionNo}`}
        data-tip
        data-for={componentId}
      >
        {accessSessionText}
        <LoadingIndicator isLoading={isLoading} className="hideText" />
      </a>
      {__CAPACITOR__ && (
        <p className={styles.capacitorAccessMessage}>
          {!isWithinAccessPeriod && <AccessZoomNotOpened />}
          <AccessZoomCapacitor />
        </p>
      )}
      {!__CAPACITOR__ && !isWithinAccessPeriod && (
        <ReactTooltip className={styles.tooltip} id={componentId} effect="solid">
          <AccessZoomNotOpened />
        </ReactTooltip>
      )}
    </div>
  );
};

function mapStateToProps(
  state: Object,
  ownProps: OwnProps & { location: Object }
): MapStateToProps {
  const { location } = ownProps;

  return {
    baseUrl: selectBaseUrl(state),
    isInApp: selectIsInApp(state),
    hasZoomNativeApp: get(location, 'query.hasZoomNativeApp') === '1',
  };
}

const dispatchProps: DispatchProps = {
  loadZoomJWT: livestreamActions.loadZoomJWT,
  addNotification: notificationActions.addNotification,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(AccessZoomButton);
